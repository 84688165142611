:root {
    --surface-border: #c7ae7b;
}

li{
    list-style-type: none;
    margin-top: 7px;
    width: 40em;
}

.p-inputtext {
    border-color: var(--surface-border);
}

.p-inputtext:focus {
    border-color: var(--surface-border);
    box-shadow: 1px 2px 4px #8d7140;
}

