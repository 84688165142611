.botaodourado{
    background-color: #c7ae7b;
    color: white;
    border: none;
    border-radius: 8px; 
    cursor: pointer;
    margin: 20px auto 0 auto;
}

.botaodourado:hover {
    background-color: #d2bb86;
}

.botaodourado:focus {
    box-shadow: 0 0 0 2px #fff, 0 0 0 4px #d2bb86, 0 1px 2px 0 #000;
}
